import * as React from "react";

function NextButtonIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      width="100%"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={27.5} cy={27.5} fill="#F3F2EF" r={27.5} />
      <path
        d="M22.5 37.5l10-10-10-10"
        stroke="#989898"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default NextButtonIcon;
