import * as React from "react";

function StarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      width="100%"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={17.5} cy={17.5} r={17.5} fill="#fff" />
      <path
        d="M17.5 24.288L25.225 29l-2.05-8.88L30 14.145l-8.988-.77L17.5 5l-3.512 8.375-8.988.77 6.825 5.975L9.775 29l7.725-4.712z"
        fill="#FFC700"
      />
    </svg>
  );
}

export default StarIcon;
