import * as React from "react";

function PersonIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      width="100%"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={17.5} cy={17.5} r={17.5} fill="#fff" />
      <path
        d="M18 17c3.039 0 5.5-2.461 5.5-5.5S21.039 6 18 6a5.498 5.498 0 00-5.5 5.5c0 3.039 2.461 5.5 5.5 5.5zm0 2.75c-3.671 0-11 1.843-11 5.5V28h22v-2.75c0-3.657-7.329-5.5-11-5.5z"
        fill="#7777F9"
      />
    </svg>
  );
}

export default PersonIcon;
