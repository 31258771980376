import React, { useContext } from "react";

import { SEOReactHelmet } from "../../components/SEO/SEOReactHelmet";

import { t } from "@lingui/macro";

import { LoginContext } from "../../components/LoginProvider/LoginProvider";
import { useNavigate } from "../../components/Link/Link";
import { LoggedInHomePage } from "../../pages";

import { LanguageSuggestionBar } from "./components/LanguageSuggestionBar";
import { HeroSection } from "./components/HeroSection";
import { SocialProofBar } from "./components/SocialProofBar";
import { HowItWorksSection } from "./components/HowItWorksSection";
import { IsItForMeSection } from "./components/IsItForMeSection";
import { MeetTheExpertsSection } from "./components/MeetTheExpertsSection";
import { ArticleShowcaseSection } from "./components/ArticleShowcaseSection";
import { WhatToExpectSection } from "./components/WhatToExpectSection";
import { SuccessStoriesSection } from "./components/SuccessStoriesSection";
import { TakeTheQuizSection } from "./components/TakeTheQuizSection";

const LoggedOutHomePage = () => {
  const tt = useTranslations();

  // Redirect logged-in users to logged-in home page
  const navigate = useNavigate();
  const { loggedIn } = useContext(LoginContext);
  if (loggedIn) {
    navigate({ to: LoggedInHomePage });
  }

  return (
    <>
      <SEOReactHelmet title={tt.seoTitle} description={tt.seoDescription} />

      <LanguageSuggestionBar />
      <HeroSection />
      <SocialProofBar />
      <HowItWorksSection />
      <IsItForMeSection />
      <MeetTheExpertsSection />
      <ArticleShowcaseSection />
      <WhatToExpectSection />
      <SuccessStoriesSection />
      <TakeTheQuizSection />
    </>
  );
};

function useTranslations() {
  return {
    seoTitle: t({
      id: "LoggedOutHomePage.seoTitle",
      message: "Diet Doctor - Improve your health one meal at a time",
    }),
    seoDescription: t({
      id: "LoggedOutHomePage.seoDescription",
      message:
        "Are you ready for a healthier you? Here, at Diet Doctor, we have the tools to help you on your wellness journey. Diet Doctor, where your health comes first.",
    }),
  };
}

// ts-prune-ignore-next
export default LoggedOutHomePage;
