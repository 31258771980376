import React, { useContext, useEffect, useMemo, useState } from "react";
import NextButtonIcon from "../icons/NextButtonIcon";
import PrevButtonIcon from "../icons/PrevButtonIcon";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react.es";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { t, Trans } from "@lingui/macro";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { Locale } from "../../../types/Locale";

export function ArticleShowcaseSection() {
  const { locale } = useContext(pageContext);

  const tt = useTranslations();

  enum Filter {
    POPULAR = "POPULAR",
    LOW_CARB = "LOW_CARB",
    KETO = "KETO",
    HIGH_PROTEIN = "HIGH_PROTEIN",
    HIGHER_SATIETY_EATING = "HIGHER_SATIETY_EATING",
    INTERMITTENT_FASTING = "INTERMITTENT_FASTING",
  }

  const [selectedFilter, setSelectedFilter] = useState<Filter>(Filter.POPULAR);

  const imageData = useStaticQuery(graphql`
    query {
      higherSatietyEating: file(relativePath: { eq: "logged-out-home/guide-higher-satiety-eating.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 210)
        }
      }
      ketoDiet: file(relativePath: { eq: "logged-out-home/guide-keto-diet.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 210)
        }
      }
      lowCarbDiet: file(relativePath: { eq: "logged-out-home/guide-low-carb-diet.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 210)
        }
      }
      highProteinDiet: file(relativePath: { eq: "logged-out-home/guide-high-protein-diet.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 210)
        }
      }
      intermittentFasting: file(relativePath: { eq: "logged-out-home/guide-intermittent-fasting.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 210)
        }
      }
      highSatietyFoods: file(relativePath: { eq: "logged-out-home/guide-high-satiety-foods.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 210)
        }
      }
      higherSatietyMealPlans: file(
        relativePath: { eq: "logged-out-home/guide-higher-satiety-meal-plans.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 210)
        }
      }
      satietyPerCalorie: file(relativePath: { eq: "logged-out-home/guide-satiety-per-calorie.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 210)
        }
      }
      ketoFoods: file(relativePath: { eq: "logged-out-home/guide-keto-foods.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 210)
        }
      }
      ketoMealPlans: file(relativePath: { eq: "logged-out-home/guide-keto-meal-plans.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 210)
        }
      }
      lowCarbFoods: file(relativePath: { eq: "logged-out-home/guide-low-carb-foods.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 210)
        }
      }
      lowCarbMealPlans: file(relativePath: { eq: "logged-out-home/guide-low-carb-meal-plans.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 210)
        }
      }
      highProteinFoods: file(relativePath: { eq: "logged-out-home/guide-high-protein-foods.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 210)
        }
      }
      highProteinMealPlans: file(relativePath: { eq: "logged-out-home/guide-high-protein-meal-plans.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 210)
        }
      }
      intermittentFastingForWeightLoss: file(
        relativePath: { eq: "logged-out-home/guide-intermittent-fasting-for-weight-loss.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 210)
        }
      }
      timeRestrictedEating: file(relativePath: { eq: "logged-out-home/guide-time-restricted-eating.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 210)
        }
      }
    }
  `);

  const guideLabel = t({
    id: "ArticleShowcaseSection.guideLabel",
    message: "GUIDE",
  });

  type Article = {
    filters: Filter[];
    image: any;
    label: string;
    title: string;
    link: string;
  };

  const articles = useMemo(
    () =>
      [
        locale !== Locale.SV
          ? {
              filters: [Filter.POPULAR, Filter.HIGHER_SATIETY_EATING],
              image: imageData.higherSatietyEating,
              label: guideLabel,
              title: t({
                id: "ArticleShowcaseSection.article.higherSatietyEating.title",
                message: "Higher-satiety eating",
              }),
              link: t({
                id: "ArticleShowcaseSection.article.higherSatietyEating.link",
                message: "https://www.dietdoctor.com/satiety",
              }),
            }
          : undefined,
        {
          filters: [Filter.POPULAR, Filter.KETO],
          image: imageData.ketoDiet,
          label: guideLabel,
          title: t({
            id: "ArticleShowcaseSection.article.ketoDiet.title",
            message: "Keto diet",
          }),
          link: t({
            id: "ArticleShowcaseSection.article.ketoDiet.link",
            message: "https://www.dietdoctor.com/low-carb/keto",
          }),
        },
        {
          filters: [Filter.POPULAR, Filter.LOW_CARB],
          image: imageData.lowCarbDiet,
          label: guideLabel,
          title: t({
            id: "ArticleShowcaseSection.article.lowCarbDiet.title",
            message: "Low carb diet",
          }),
          link: t({
            id: "ArticleShowcaseSection.article.lowCarbDiet.link",
            message: "https://www.dietdoctor.com/low-carb",
          }),
        },
        {
          filters: [Filter.POPULAR, Filter.HIGH_PROTEIN],
          image: imageData.highProteinDiet,
          label: guideLabel,
          title: t({
            id: "ArticleShowcaseSection.article.highProteinDiet.title",
            message: "High protein diet",
          }),
          link: t({
            id: "ArticleShowcaseSection.article.highProteinDiet.link",
            message: "https://www.dietdoctor.com/high-protein",
          }),
        },
        {
          filters: [Filter.POPULAR, Filter.INTERMITTENT_FASTING],
          image: imageData.intermittentFasting,
          label: guideLabel,
          title: t({
            id: "ArticleShowcaseSection.article.intermittentFasting.title",
            message: "Intermittent fasting",
          }),
          link: t({
            id: "ArticleShowcaseSection.article.intermittentFasting.link",
            message: "https://www.dietdoctor.com/intermittent-fasting",
          }),
        },
        locale !== Locale.SV
          ? {
              filters: [Filter.HIGHER_SATIETY_EATING],
              image: imageData.highSatietyFoods,
              label: guideLabel,
              title: t({
                id: "ArticleShowcaseSection.article.highSatietyFoods.title",
                message: "High-satiety foods",
              }),
              link: t({
                id: "ArticleShowcaseSection.article.highSatietyFoods.link",
                message: "https://www.dietdoctor.com/satiety/foods",
              }),
            }
          : undefined,
        locale !== Locale.SV && locale !== Locale.ES
          ? {
              filters: [Filter.HIGHER_SATIETY_EATING],
              image: imageData.higherSatietyMealPlans,
              label: guideLabel,
              title: t({
                id: "ArticleShowcaseSection.article.higherSatietyMealPlans.title",
                message: "Higher-satiety meal plans",
              }),
              link: t({
                id: "ArticleShowcaseSection.article.higherSatietyMealPlans.link",
                message: "https://www.dietdoctor.com/satiety/meal-plan",
              }),
            }
          : undefined,
        locale !== Locale.SV
          ? {
              filters: [Filter.HIGHER_SATIETY_EATING],
              image: imageData.satietyPerCalorie,
              label: guideLabel,
              title: t({
                id: "ArticleShowcaseSection.article.satietyPerCalorie.title",
                message: "Satiety per calorie",
              }),
              link: t({
                id: "ArticleShowcaseSection.article.satietyPerCalorie.link",
                message: "https://www.dietdoctor.com/satiety/science",
              }),
            }
          : undefined,
        {
          filters: [Filter.KETO],
          image: imageData.ketoFoods,
          label: guideLabel,
          title: t({
            id: "ArticleShowcaseSection.article.ketoFoods.title",
            message: "Keto foods",
          }),
          link: t({
            id: "ArticleShowcaseSection.article.ketoFoods.link",
            message: "https://www.dietdoctor.com/low-carb/keto/foods",
          }),
        },
        {
          filters: [Filter.KETO],
          image: imageData.ketoMealPlans,
          label: guideLabel,
          title: t({
            id: "ArticleShowcaseSection.article.ketoMealPlans.title",
            message: "Keto meal plans",
          }),
          link: t({
            id: "ArticleShowcaseSection.article.ketoMealPlans.link",
            message: "https://www.dietdoctor.com/low-carb/keto/diet-plan",
          }),
        },
        {
          filters: [Filter.LOW_CARB],
          image: imageData.lowCarbFoods,
          label: guideLabel,
          title: t({
            id: "ArticleShowcaseSection.article.lowCarbFoods.title",
            message: "Low carb foods",
          }),
          link: t({
            id: "ArticleShowcaseSection.article.lowCarbFoods.link",
            message: "https://www.dietdoctor.com/low-carb/foods",
          }),
        },
        {
          filters: [Filter.LOW_CARB],
          image: imageData.lowCarbMealPlans,
          label: guideLabel,
          title: t({
            id: "ArticleShowcaseSection.article.lowCarbMealPlans.title",
            message: "Low carb meal plans",
          }),
          link: t({
            id: "ArticleShowcaseSection.article.lowCarbMealPlans.link",
            message: "https://www.dietdoctor.com/low-carb/diet-meal-plan",
          }),
        },
        {
          filters: [Filter.HIGH_PROTEIN],
          image: imageData.highProteinFoods,
          label: guideLabel,
          title: t({
            id: "ArticleShowcaseSection.article.highProteinFoods.title",
            message: "High protein foods",
          }),
          link: t({
            id: "ArticleShowcaseSection.article.highProteinFoods.link",
            message: "https://www.dietdoctor.com/high-protein/foods",
          }),
        },
        {
          filters: [Filter.HIGH_PROTEIN],
          image: imageData.highProteinMealPlans,
          label: guideLabel,
          title: t({
            id: "ArticleShowcaseSection.article.highProteinMealPlans.title",
            message: "High protein meal plans",
          }),
          link: t({
            id: "ArticleShowcaseSection.article.highProteinMealPlans.link",
            message: "https://www.dietdoctor.com/high-protein/diet-plan",
          }),
        },
        {
          filters: [Filter.INTERMITTENT_FASTING],
          image: imageData.intermittentFastingForWeightLoss,
          label: guideLabel,
          title: t({
            id: "ArticleShowcaseSection.article.intermittentFastingForWeightLoss.title",
            message: "Intermittent fasting for weight loss",
          }),
          link: t({
            id: "ArticleShowcaseSection.article.intermittentFastingForWeightLoss.link",
            message: "https://www.dietdoctor.com/intermittent-fasting/weight-loss",
          }),
        },
        locale !== Locale.SV
          ? {
              filters: [Filter.INTERMITTENT_FASTING],
              image: imageData.timeRestrictedEating,
              label: guideLabel,
              title: t({
                id: "ArticleShowcaseSection.article.timeRestrictedEating.title",
                message: "Time-restricted eating",
              }),
              link: t({
                id: "ArticleShowcaseSection.article.timeRestrictedEating.link",
                message: "https://www.dietdoctor.com/intermittent-fasting/time-restricted-eating",
              }),
            }
          : undefined,
      ].filter((obj): obj is Article => !!obj),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Filter]
  );

  const filteredArticles = useMemo(() => {
    return articles.filter((article) =>
      selectedFilter === null ? article : article.filters.includes(selectedFilter)
    );
  }, [articles, selectedFilter]);

  const [sliderLoaded, setSliderLoaded] = useState(false);
  const [sliderRef, sliderInstanceRef] = useKeenSlider({
    renderMode: "performance",
    mode: "free-snap",
    loop: false,
    slides: { perView: 1.3, spacing: 15 },
    breakpoints: {
      "(min-width: 640px)": {
        slides: { perView: 2.3, spacing: 15 },
      },
      "(min-width: 1024px)": {
        slides: { perView: 3.6, spacing: 15 },
      },
    },
    created() {
      setSliderLoaded(true);
    },
  });

  useEffect(() => {
    sliderInstanceRef && sliderInstanceRef.current?.update();
  }, [filteredArticles, sliderInstanceRef]);

  return (
    <section className="py-[4.5rem] overflow-x-hidden content-auto">
      <div className="max-w-7xl px-4 mx-auto">
        <h2 className="text-[1.6875rem] leading-[2.25rem] md:text-4xl font-normal m-0">{tt.header}</h2>
        <h3 className="m-0 opacity-80 text-lg md:text-xl font-normal max-w-2xl mt-2">{tt.description}</h3>

        {/* Filter & controls on same row */}
        <div className="flex flex-row justify-between items-center mt-6">
          {/* Filter bar */}
          <div className="flex flex-col lg:space-x-2 lg:flex-row lg:items-center w-max py-2 rounded-lg m-0 lg:shrink-0 text-base select-none">
            <div className="flex flex-row space-x-2">
              <div
                className={`cursor-pointer text-lg py-2 px-3.5 md:px-6 mt-2 rounded-full transition-colors duration-150 ${
                  selectedFilter === Filter.POPULAR
                    ? "bg-[#37664E] font-medium text-white"
                    : "bg-light-beige hover:bg-[#F8F7F4] text-[#3C3C3C] font-normal"
                }`}
                onClick={() => setSelectedFilter(Filter.POPULAR)}
              >
                {tt.popular}
              </div>
              <div
                className={`cursor-pointer text-lg py-2 px-3.5 md:px-6 mt-2 rounded-full transition-colors duration-150 ${
                  selectedFilter === Filter["LOW_CARB"]
                    ? "bg-[#37664E] font-medium text-white"
                    : "bg-light-beige hover:bg-[#F8F7F4] text-[#3C3C3C] font-normal"
                }`}
                onClick={() => setSelectedFilter(Filter["LOW_CARB"])}
              >
                {tt.lowCarb}
              </div>
              <div
                className={`cursor-pointer text-lg py-2 px-3.5 md:px-6 mt-2 rounded-full transition-colors duration-150 ${
                  selectedFilter === Filter.KETO
                    ? "bg-[#37664E] font-medium text-white"
                    : "bg-light-beige hover:bg-[#F8F7F4] text-[#3C3C3C] font-normal"
                }`}
                onClick={() => setSelectedFilter(Filter.KETO)}
              >
                {tt.keto}
              </div>
            </div>
            <div className="flex flex-row space-x-2">
              <div
                className={`cursor-pointer text-lg py-2 px-3.5 md:px-6 mt-2 rounded-full transition-colors duration-150 ${
                  selectedFilter === Filter["HIGH_PROTEIN"]
                    ? "bg-[#37664E] font-medium text-white"
                    : "bg-light-beige hover:bg-[#F8F7F4] text-[#3C3C3C] font-normal"
                }`}
                onClick={() => setSelectedFilter(Filter["HIGH_PROTEIN"])}
              >
                {tt.highProtein}
              </div>
              {locale !== Locale.SV && (
                <div
                  className={`cursor-pointer text-lg py-2 px-3.5 md:px-6 mt-2 rounded-full transition-colors duration-150 ${
                    selectedFilter === Filter["HIGHER_SATIETY_EATING"]
                      ? "bg-[#37664E] font-medium text-white"
                      : "bg-light-beige hover:bg-[#F8F7F4] text-[#3C3C3C] font-normal"
                  }`}
                  onClick={() => setSelectedFilter(Filter["HIGHER_SATIETY_EATING"])}
                >
                  {tt.higherSatietyEating}
                </div>
              )}
            </div>
            <div className="flex flex-row space-x-2">
              <div
                className={`cursor-pointer text-lg py-2 px-3.5 md:px-6 mt-2 rounded-full transition-colors duration-150 ${
                  selectedFilter === Filter["INTERMITTENT_FASTING"]
                    ? "bg-[#37664E] font-medium text-white"
                    : "bg-light-beige hover:bg-[#F8F7F4] text-[#3C3C3C] font-normal"
                }`}
                onClick={() => setSelectedFilter(Filter["INTERMITTENT_FASTING"])}
              >
                {tt.intermittentFasting}
              </div>
            </div>
          </div>

          {/* Controls */}
          {sliderLoaded && sliderInstanceRef.current && filteredArticles.length > 3 && (
            <>
              <div className="w-full hidden xl:flex flex-row justify-end select-none">
                <div
                  className="flex w-11 h-11 mx-4 bg-green-500 left-0 z-30 rounded-full cursor-pointer items-center justify-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    sliderInstanceRef.current?.prev();
                  }}
                >
                  <PrevButtonIcon className="hover:opacity-90" />
                </div>
                <div
                  className="flex w-11 h-11 mx-4 bg-green-500 right-0 z-30 rounded-full cursor-pointer items-center justify-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    sliderInstanceRef.current?.next();
                  }}
                >
                  <NextButtonIcon className="hover:opacity-90" />
                </div>
              </div>
            </>
          )}
        </div>

        {/* Articles carousel */}
        <div
          ref={sliderRef}
          className={`keen-slider !overflow-visible min-h-min w-screen flex flex-row m-0 mt-6 ${
            filteredArticles.length > 2 ? "hover:cursor-grab active:cursor-grabbing" : ""
          }`}
        >
          {filteredArticles.map((item, index) => (
            <SingleArticle
              key={index}
              image={item.image}
              label={item.label}
              title={item.title}
              link={item.link}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

function useTranslations() {
  return {
    header: (
      <Trans id="ArticleShowcaseSection.header">
        Customizable approach. <b className="whitespace-nowrap">Lasting results.</b>
      </Trans>
    ),
    description: t({
      id: "ArticleShowcaseSection.description",
      message:
        "You’ll find success doing it your way, not our way. Expect a science-based approach that you’ll enjoy — and results you’ll love.",
    }),
    popular: t({
      id: "ArticleShowcaseSection.popular",
      message: "Popular",
    }),
    lowCarb: t({
      id: "ArticleShowcaseSection.lowCarb",
      message: "Low carb",
    }),
    keto: t({
      id: "ArticleShowcaseSection.keto",
      message: "Keto",
    }),
    highProtein: t({
      id: "ArticleShowcaseSection.highProtein",
      message: "High protein",
    }),
    higherSatietyEating: t({
      id: "ArticleShowcaseSection.higherSatietyEating",
      message: "Higher-satiety eating",
    }),
    intermittentFasting: t({
      id: "ArticleShowcaseSection.intermittentFasting",
      message: "Intermittent fasting",
    }),
    learnMore: t({
      id: "ArticleShowcaseSection.learnMore",
      message: "Learn more",
    }),
  };
}

function SingleArticle({
  image,
  label,
  title,
  link,
}: {
  image: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  label: string;
  title: string;
  link: string;
}) {
  const tt = useTranslations();

  return (
    <div className="keen-slider__slide transform-gpu min-w-[260px] bg-white rounded-lg shadow-lg">
      <a href={link} className="flex flex-col h-full group">
        <GatsbyImage
          image={image?.childImageSharp?.gatsbyImageData}
          className="w-full max-h-[320px] aspect-[16/9] rounded-t-lg bg-lighter-grey overflowfix"
          alt={"article thumbnail"}
        />
        <div className="p-3">
          <p className="m-0 uppercase text-sm text-black font-medium tracking-wider opacity-75">{label}</p>
          <h4 className="m-0 mt-1 text-xl text-black font-medium">{title}</h4>
          <div className="m-0 mt-auto pt-4 pb-2 text-md text-green font-medium group-hover:underline">
            {tt.learnMore}
          </div>
        </div>
      </a>
    </div>
  );
}
