import { t, Trans } from "@lingui/macro";
import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage, withArtDirection } from "gatsby-plugin-image";
import React, { useContext } from "react";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { OnboardingStep } from "../../onboarding/types";
import { localePrefix } from "../../../pages";
import { Locale } from "../../../types/Locale";

export function HeroSection() {
  const { locale } = useContext(pageContext);

  const tt = useTranslations();

  const imageData = useStaticQuery(graphql`
    query {
      desktopEN: file(relativePath: { eq: "logged-out-home/hero-desktop-en.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 624)
        }
      }
      mobileEN: file(relativePath: { eq: "logged-out-home/hero-mobile-en.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 424)
        }
      }
      desktopSV: file(relativePath: { eq: "logged-out-home/hero-desktop-sv.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 624)
        }
      }
      mobileSV: file(relativePath: { eq: "logged-out-home/hero-mobile-sv.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 424)
        }
      }
      desktopES: file(relativePath: { eq: "logged-out-home/hero-desktop-es.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 624)
        }
      }
      mobileES: file(relativePath: { eq: "logged-out-home/hero-mobile-es.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 424)
        }
      }
    }
  `);

  const imageSources =
    locale === Locale.SV
      ? withArtDirection(imageData.mobileSV?.childImageSharp?.gatsbyImageData, [
          {
            media: "(min-width: 1024px)",
            image: imageData.desktopSV?.childImageSharp?.gatsbyImageData,
          },
        ])
      : locale === Locale.ES
      ? withArtDirection(imageData.mobileES?.childImageSharp?.gatsbyImageData, [
          {
            media: "(min-width: 1024px)",
            image: imageData.desktopES?.childImageSharp?.gatsbyImageData,
          },
        ])
      : withArtDirection(imageData.mobileEN?.childImageSharp?.gatsbyImageData, [
          {
            media: "(min-width: 1024px)",
            image: imageData.desktopEN?.childImageSharp?.gatsbyImageData,
          },
        ]);

  return (
    <section className="bg-light-beige">
      <div className="w-full max-w-7xl px-4 mx-auto pt-16 pb-8 flex flex-col lg:flex-row justify-center items-center md:space-y-0  overflow-x-hidden">
        <div className="flex flex-col">
          <h1 className="m-0 max-w-xl font-medium !leading-[1.125] text-3xl md:text-5xl -pr-6">{tt.header}</h1>
          <h2 className="m-0 mt-5 max-w-lg font-normal text-xl md:text-2xl">{tt.subheader}</h2>
          <Link
            to={`${localePrefix[locale]}/onboarding/${OnboardingStep.GENDER}`}
            className="mt-7 max-w-max font-medium text-xl bg-green text-white px-8 py-3 rounded-full hover:underline focus:opacity-95 transition-opacity duration-75"
          >
            {tt.cta}
          </Link>
          <p className="m-0 mt-[0.875.rem]">{tt.info}</p>
        </div>

        <GatsbyImage
          image={imageSources}
          objectFit="contain"
          alt="hero section showcasing app"
          loading="eager"
          className="!min-w-[392px] !mt-2 !-mb-8 lg:!m-0 lg:!h-[576px] lg:max-w-[50%]"
        />
      </div>
    </section>
  );
}

function useTranslations() {
  return {
    header: t({
      id: "HeroSection.header",
      message: "Discover what foods work for you",
    }),
    subheader: t({
      id: "HeroSection.subheader",
      message: "Let yourself be guided by our experts and your wellness goals will be within reach.",
    }),
    cta: t({
      id: "HeroSection.cta",
      message: "Take the quiz",
    }),
    info: (
      <Trans id="HeroSection.info">
        It’s quick and <b>free</b>.
      </Trans>
    ),
  };
}
