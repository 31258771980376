import React, { useContext } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { localePrefix } from "../../../pages";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { OnboardingStep } from "../../onboarding/types";
import { Link } from "gatsby";
import { t, Trans } from "@lingui/macro";
import { Locale } from "../../../types/Locale";

export function WhatToExpectSection() {
  const { locale } = useContext(pageContext);

  const tt = useTranslations();

  return (
    <section className="py-[4.5rem] bg-light-beige content-auto">
      <div className="max-w-7xl mx-auto px-4">
        {/* Headings */}
        <h2 className="uppercase text-center tracking-widest text-base font-medium m-0">{tt.subheader}</h2>
        <h3 className="m-0 mt-4 text-center text-[1.6875rem] leading-[2.25rem] md:text-4xl font-normal">
          {tt.header}
        </h3>

        {/* Steps */}
        <div className="flex flex-col items-center mt-12 mx-auto">
          {/* Step one */}
          <div className="w-full flex flex-row justify-center space-x-8 sm:space-x-12">
            <div className="relative opacity-50">
              <div className="border-l-[2.5px] h-full border-grey"></div>
              <div className="absolute inset-auto h-6 w-6 bg-grey rounded-full top-[32.5%] lg:top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"></div>
            </div>

            <div className="flex flex-col lg:flex-row lg:space-x-12">
              {locale === Locale.SV ? (
                <StaticImage
                  src={"../../../../static/images/logged-out-home/lunch-card-sv.jpg"}
                  width={368}
                  objectFit="contain"
                  alt="recipes"
                  className="max-w-[328px] md:max-w-[368px] rounded-xl overflowfix"
                />
              ) : locale === Locale.ES ? (
                <StaticImage
                  src={"../../../../static/images/logged-out-home/lunch-card-es.jpg"}
                  width={368}
                  objectFit="contain"
                  alt="recipes"
                  className="max-w-[328px] md:max-w-[368px] rounded-xl overflowfix"
                />
              ) : (
                <StaticImage
                  src={"../../../../static/images/logged-out-home/lunch-card-en.jpg"}
                  width={368}
                  objectFit="contain"
                  alt="recipes"
                  className="max-w-[328px] md:max-w-[368px] rounded-xl overflowfix"
                />
              )}

              <div className="max-w-md">
                <h4 className="mt-4 lg:mt-3 text-2xl lg:text-3xl font-medium lg:pr-10">{tt.stepOneTitle}</h4>
                <p className="m-0 mt-2 font-normal text-base md:text-lg">{tt.stepOneDescription}</p>
              </div>
            </div>
          </div>

          {/* Step two */}
          <div className="w-full flex flex-row justify-center space-x-8 sm:space-x-12">
            <div className="relative opacity-50">
              <div className="border-l-[2.5px] h-full border-grey"></div>
              <div className="absolute inset-auto h-6 w-6 bg-grey rounded-full top-[32.5%] lg:top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"></div>
            </div>
            <div className="pt-14 lg:pt-18 flex flex-col lg:flex-row lg:space-x-12">
              {locale === Locale.SV ? (
                <StaticImage
                  src="../../../../static/images/logged-out-home/learn-card-sv.jpg"
                  width={368}
                  objectFit="contain"
                  alt="knowledge"
                  className="max-w-[328px] md:max-w-[368px] rounded-xl overflowfix"
                />
              ) : locale === Locale.ES ? (
                <StaticImage
                  src="../../../../static/images/logged-out-home/learn-card-es.jpg"
                  width={368}
                  objectFit="contain"
                  alt="knowledge"
                  className="max-w-[328px] md:max-w-[368px] rounded-xl overflowfix"
                />
              ) : (
                <StaticImage
                  src="../../../../static/images/logged-out-home/learn-card-en.jpg"
                  width={368}
                  objectFit="contain"
                  alt="knowledge"
                  className="max-w-[328px] md:max-w-[368px] rounded-xl overflowfix"
                />
              )}

              <div className="max-w-md">
                <h4 className="mt-4 lg:mt-3 text-2xl lg:text-3xl font-medium">{tt.stepTwoTitle}</h4>
                <p className="m-0 mt-2 font-normal text-base md:text-lg">{tt.stepTwoDescription}</p>
              </div>
            </div>
          </div>

          {/* Step three */}
          <div className="w-full flex flex-row justify-center space-x-8 sm:space-x-12">
            <div className="relative opacity-50">
              <div className="border-l-[2.5px] h-full border-grey"></div>
              <div className="absolute inset-auto h-6 w-6 bg-grey rounded-full top-[32.5%] lg:top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"></div>
            </div>
            <div className="pt-14 lg:pt-18 flex flex-col lg:flex-row lg:space-x-12">
              {locale === Locale.SV ? (
                <StaticImage
                  src="../../../../static/images/logged-out-home/reflect-card-sv.jpg"
                  width={368}
                  objectFit="contain"
                  alt="support"
                  className="max-w-[328px] md:max-w-[368px] rounded-xl overflowfix"
                />
              ) : locale === Locale.ES ? (
                <StaticImage
                  src="../../../../static/images/logged-out-home/reflect-card-es.jpg"
                  width={368}
                  objectFit="contain"
                  alt="support"
                  className="max-w-[328px] md:max-w-[368px] rounded-xl overflowfix"
                />
              ) : (
                <StaticImage
                  src="../../../../static/images/logged-out-home/reflect-card-en.jpg"
                  width={368}
                  objectFit="contain"
                  alt="support"
                  className="max-w-[328px] md:max-w-[368px] rounded-xl overflowfix"
                />
              )}

              <div className="max-w-md">
                <h4 className="mt-4 lg:mt-3 text-2xl lg:text-3xl font-medium">{tt.stepThreeTitle}</h4>
                <p className="m-0 mt-2 font-normal text-base md:text-lg">{tt.stepThreeDescription}</p>
              </div>
            </div>
          </div>
        </div>

        {/* CTA */}
        <Link
          to={`${localePrefix[locale]}/onboarding/${OnboardingStep.GENDER}`}
          className="!mt-[3.375rem] md:!mt-[4.5rem] block mx-auto max-w-max font-medium text-xl bg-green text-white px-8 py-3 rounded-full hover:underline focus:opacity-95 transition-opacity duration-75"
        >
          {tt.cta}
        </Link>
      </div>
    </section>
  );
}
function useTranslations() {
  return {
    subheader: t({
      id: "WhatToExpectSection.subheader",
      message: "What to expect",
    }),
    header: (
      <Trans id="WhatToExpectSection.header">
        <b>Guidance</b> at your fingertips
      </Trans>
    ),

    stepOneTitle: t({
      id: "WhatToExpectSection.stepOneTitle",
      message: "Discover the food that works for you",
    }),
    stepOneDescription: t({
      id: "WhatToExpectSection.stepOneDescription",
      message:
        "Use our recipes, meal plans, and smart shopping list, or learn to put together easy meals from restaurants or takeout. Wherever you eat, we’ll help you find the best options.",
    }),

    stepTwoTitle: t({
      id: "WhatToExpectSection.stepTwoTitle",
      message: "Knowledge is power",
    }),
    stepTwoDescription: t({
      id: "WhatToExpectSection.stepTwoDescription",
      message:
        "Learn about more than just food. Understand some basic nutrition science, and get helpful tips about forming new habits. Our courses, guides, and videos have it all.",
    }),

    stepThreeTitle: t({
      id: "WhatToExpectSection.stepThreeTitle",
      message: "Expert support is key",
    }),
    stepThreeDescription: t({
      id: "WhatToExpectSection.stepThreeDescription",
      message:
        "Questions and bumps in the road will pop up. Our community of members and experts are here with answers and inspiration. Find the support you need, and help others, too.",
    }),

    cta: t({
      id: "WhatToExpectSection.cta",
      message: "Sign up today",
    }),
  };
}
