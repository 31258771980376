import * as React from "react";

function SwedenFlagIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      width="100%"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 5.675A20.002 20.002 0 004.249 21H16V5.675zM43.751 27H22v16.899c.658.065 1.325.101 2 .101 10.023 0 18.3-7.381 19.751-17zM4.249 27A20.002 20.002 0 0016 42.325V27H4.249zM43.751 21C42.3 11.381 34.023 4 24 4c-.675 0-1.342.036-2 .101V21h21.751z"
        fill="#1976D2"
      />
      <path
        d="M43.751 27c.148-.981.249-1.978.249-3s-.101-2.02-.249-3H22V4.1c-2.116.21-4.13.758-6 1.575V21H4.249A20.047 20.047 0 004 24c0 1.022.101 2.019.249 3H16v15.325a19.836 19.836 0 006 1.574v-16.9h21.751z"
        fill="#FFC107"
      />
    </svg>
  );
}

export default SwedenFlagIcon;
