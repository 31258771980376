import { Trans, t } from "@lingui/macro";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react.es";
import React, { useContext, useState } from "react";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { Locale } from "../../../types/Locale";

export function MeetTheExpertsSection() {
  const { locale } = useContext(pageContext);

  const tt = useTranslations();

  const imageData = useStaticQuery(graphql`
    query {
      andreas: file(relativePath: { eq: "experts/andreas.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 224)
        }
      }
      bret: file(relativePath: { eq: "experts/bret.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 224)
        }
      }
      ted: file(relativePath: { eq: "experts/ted.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 224)
        }
      }
    }
  `);

  const [sliderRef] = useKeenSlider({
    renderMode: "performance",
    mode: "free-snap",
    loop: false,
    slides: { perView: 1.63, spacing: 5, origin: "auto" },
    breakpoints: {
      "(min-width: 520px)": {
        slides: { perView: 2.25, spacing: 5, origin: "auto" },
      },
      "(min-width: 768px)": {
        slides: { perView: 4, spacing: 5, origin: "auto" },
      },
    },
  });

  return (
    <section className="bg-blue-600 py-[4.5rem] overflow-x-hidden">
      <div className="max-w-7xl px-4 mx-auto">
        <h2 className="m-0 text-[1.6875rem] leading-[2.25rem] md:text-4xl font-normal text-white">
          {tt.header}
        </h2>
        <p className="opacity-80 font-normal text-white text-lg md:text-xl max-w-2xl m-0 mt-2">
          {tt.description}
        </p>

        <ul
          ref={sliderRef}
          className={`keen-slider w-full min-h-min flex flex-row justify-start list-none p-0 m-0 mt-8 hover:cursor-grab active:cursor-grabbing md:hover:cursor-auto !overflow-visible`}
        >
          {/* Andreas */}
          <SingleExpert
            image={imageData.andreas?.childImageSharp?.gatsbyImageData}
            title={tt.andreasTitle}
            description={tt.andreasDescription}
            moreInfo={(locale === Locale.EN && tt.andreasMoreInfo) || undefined}
          />

          {/* Ted */}
          <SingleExpert
            image={imageData.ted?.childImageSharp?.gatsbyImageData}
            title={tt.tedTitle}
            description={tt.tedDescription}
            moreInfo={(locale === Locale.EN && tt.tedMoreInfo) || undefined}
          />
        </ul>
      </div>
    </section>
  );
}

function useTranslations() {
  return {
    header: (
      <Trans id="MeetTheExpertsSection.header">
        Meet the <b>experts</b>
      </Trans>
    ),
    description: t({
      id: "MeetTheExpertsSection.description",
      message:
        "We aim to provide unbiased guidance rooted in evidence-based information, nutritionally-reviewed recipes that satisfy, and inspiring tools to help you reach your goals in a sustainable way.",
    }),

    andreasTitle: t({
      id: "MeetTheExpertsSection.andreasTitle",
      message: "Andreas Eenfeldt, MD",
    }),
    andreasDescription: t({
      id: "MeetTheExpertsSection.andreasDescription",
      message: "Founder & CEO",
    }),
    andreasMoreInfo: t({
      id: "MeetTheExpertsSection.andreasMoreInfo",
      message:
        "Dr. Andreas Eenfeldt is the founder and CEO of Diet Doctor. He practiced family medicine for over 12 years before deciding to help more people by moving to his full-time role leading Diet Doctor in its mission to empower people to dramatically improve their heath. His top areas of focus are product definition, strategic planning, and operations.",
    }),

    tedTitle: t({
      id: "MeetTheExpertsSection.tedTitle",
      message: "Ted Naiman, MD",
    }),
    tedDescription: t({
      id: "MeetTheExpertsSection.tedDescription",
      message: "Senior Science Advisor",
    }),
    tedMoreInfo: t({
      id: "MeetTheExpertsSection.tedMoreInfo",
      message:
        "Dr. Ted Naiman is a primary care physician practicing in Seattle. As the author of The P:E Diet, Dr. Naiman is particularly passionate about the importance of dietary protein for proper nutrition, weight loss, and metabolic health. At Diet Doctor, he helps embed his ideas about protein into our products and programs.",
    }),
  };
}

function SingleExpert({
  image,
  title,
  description,
  moreInfo,
}: {
  image: IGatsbyImageData;
  title: string;
  description: string | React.ReactChild[];
  moreInfo?: string;
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <li
        className={`keen-slider__slide transform-gpu min-w-[208px] relative flex flex-col items-center text-center w-56 ${
          moreInfo ? "cursor-pointer" : ""
        }`}
        onClick={() => setShowModal(true)}
      >
        <GatsbyImage
          image={image}
          objectFit="contain"
          alt={title.replace("\n", " ")}
          className="w-[168px] lg:w-[224px] rounded-full bg-lighter-grey overflowfix"
        />
        <h3
          className={`m-0 font-medium text-white text-xl sm:text-2xl mt-4 whitespace-pre-wrap ${
            moreInfo ? "underline" : ""
          }`}
        >
          {title}
        </h3>
        <h4 className="m-0 mt-2 text-md whitespace-pre-wrap text-white">{description}</h4>
      </li>

      {moreInfo && showModal ? (
        <>
          <div className="fixed inset-0 z-9999 overflow-y-auto">
            {/* Black overlay */}
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-50"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen p-4">
              <div className="relative w-full max-w-lg p-6 mx-auto bg-white text-black rounded-lg shadow-lg">
                <div className="flex flex-row justify-end">
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer hover:opacity-80"
                    onClick={() => setShowModal(false)}
                  >
                    <path
                      d="M13 1.31L11.69 0 6.5 5.19 1.31 0 0 1.31 5.19 6.5 0 11.69 1.31 13 6.5 7.81 11.69 13 13 11.69 7.81 6.5 13 1.31z"
                      fill="#1A1A1A"
                    />
                  </svg>
                </div>
                <div className="relative">
                  <h3 className="m-0 mt-2 text-black text-2xl font-medium">{title}</h3>
                  <h4 className="m-0 text-black text-xl font-normal">{description}</h4>
                  <p className="m-0 mt-4 text-black leading-relaxed text-base">{moreInfo}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
