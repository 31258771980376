import * as React from "react";

function HeartIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      width="100%"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={17.5} cy={17.5} r={17.5} fill="#fff" />
      <path
        d="M22.727 9c-2.3 0-3.973 1.18-5.227 2.457C16.245 10.277 14.573 9 12.273 9 8.613 9 6 11.85 6 15.389c0 1.77.732 3.342 2.09 4.423l9.41 8.355 9.41-8.355c1.254-1.18 2.09-2.654 2.09-4.423C29 11.85 26.386 9 22.727 9z"
        fill="#FC0404"
      />
    </svg>
  );
}

export default HeartIcon;
