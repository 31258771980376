import React, { useContext, useEffect } from "react";
import { Link } from "../../../components/Link/Link";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { LoggedOutHomePage } from "../../../pages";
import { Locale } from "../../../types/Locale";
import { getLocaleFromBrowserTimezone } from "../../../utils/getLocaleFromBrowserTimezone";
import { isBrowser } from "../../../utils/ssr";
import SpainFlagIcon from "../icons/SpainFlagIcon";
import SwedenFlagIcon from "../icons/SwedenFlagIcon";
import USAFlagIcon from "../icons/USAFlagIcon";

export function LanguageSuggestionBar() {
  const { locale } = useContext(pageContext);

  if (locale === Locale.ZU && !isBrowser()) return null;

  const currentUserLocale = getLocaleFromBrowserTimezone();

  const isVisible = React.useRef(false);

  useEffect(() => {
    isVisible.current = true;
  }, []);

  if (isVisible.current === false) return null;

  return (
    <div className="px-4 absolute top-15 w-full z-10 bg-gray-blue-500">
      {(currentUserLocale === "SE" || currentUserLocale === "FI") && locale !== Locale.SV ? (
        <Link
          to={LoggedOutHomePage}
          locale={Locale.SV}
          className="flex items-center justify-center space-x-2 py-2.5 text-center text-md font-medium hover:underline"
        >
          <SwedenFlagIcon height={32} width={32} />{" "}
          <span className="opacity-70 text-blue">Diet Doctor finns också på svenska.</span>
        </Link>
      ) : (currentUserLocale === "ES" ||
          currentUserLocale === "MX" ||
          currentUserLocale === "CO" ||
          currentUserLocale === "AR" ||
          currentUserLocale === "PE" ||
          currentUserLocale === "VE" ||
          currentUserLocale === "CL" ||
          currentUserLocale === "GT" ||
          currentUserLocale === "EC" ||
          currentUserLocale === "BO" ||
          currentUserLocale === "CU" ||
          currentUserLocale === "DO" ||
          currentUserLocale === "HN" ||
          currentUserLocale === "SV" ||
          currentUserLocale === "NI" ||
          currentUserLocale === "CR" ||
          currentUserLocale === "PA" ||
          currentUserLocale === "UY" ||
          currentUserLocale === "GQ" ||
          currentUserLocale === "PR") &&
        locale !== Locale.ES ? (
        <Link
          to={LoggedOutHomePage}
          locale={Locale.ES}
          className="flex items-center justify-center space-x-2 w-full py-2.5 text-center text-light-blue text-md font-medium hover:underline"
        >
          <SpainFlagIcon width={32} height={32} />
          <span>Diet Doctor también está disponible en español.</span>
        </Link>
      ) : (
        (currentUserLocale === "US" ||
          currentUserLocale === "GB" ||
          currentUserLocale === "AU" ||
          currentUserLocale === "CA") &&
        locale !== Locale.EN && (
          <Link
            to={LoggedOutHomePage}
            locale={Locale.EN}
            className="flex items-center justify-center space-x-2 w-full py-2.5 text-center text-light-blue text-md font-medium hover:underline"
          >
            <USAFlagIcon width={32} height={32} />
            <span>Diet Doctor is also available in English.</span>
          </Link>
        )
      )}
    </div>
  );
}
