import React from "react";
import HeartIcon from "../icons/HeartIcon";
import PersonIcon from "../icons/PersonIcon";
import StarIcon from "../icons/StarIcon";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react.es";
import { Trans } from "@lingui/macro";

export function SocialProofBar() {
  const tt = useTranslations();

  const [sliderRef] = useKeenSlider(
    {
      renderMode: "performance",
      drag: false,
      slides: { perView: 1.295, spacing: 15 },
      loop: true,
      breakpoints: {
        "(min-width: 640px)": {
          slides: { perView: 2.375, spacing: 15 },
        },
      },
    },
    [
      (slider) => {
        let timeout: any;
        function nextTimeout() {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            if (!window.matchMedia("(min-width: 1024px)").matches) {
              slider.next();
            }
          }, 2680);
        }
        slider.on("created", () => {
          nextTimeout();
        });
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  return (
    <section className="w-full bg-blue-200 text-[#183152] text-base py-4">
      {/* Mobile */}
      <ul
        ref={sliderRef}
        className="keen-slider lg:!invisible lg:!hidden list-none max-w-7xl m-0 p-0 px-4 mx-auto"
      >
        <li className="keen-slider__slide transform-gpu h-[48px] min-w-[275px] flex flex-row items-center">
          <HeartIcon height={36} width={36} className="flex-shrink-0" />
          <span className="inline-block ml-3">{tt.trusted}</span>
        </li>
        <li className="keen-slider__slide transform-gpu h-[48px] min-w-[275px] flex flex-row items-center ">
          <StarIcon height={36} width={36} className="flex-shrink-0" />
          <span className="inline-block ml-3">{tt.reviews}</span>
        </li>
        <li className="keen-slider__slide transform-gpu h-[48px] min-w-[275px] flex flex-row items-center">
          <PersonIcon height={36} width={36} className="flex-shrink-0" />
          <span className="inline-block ml-3">{tt.helped}</span>
        </li>
      </ul>

      {/* Desktop */}
      <ul className="invisible hidden lg:visible lg:flex list-none max-w-7xl m-0 p-0 px-4 mx-auto flex-row justify-between space-x-4">
        <li className="flex flex-row items-center">
          <HeartIcon height={36} width={36} className="flex-shrink-0" />
          <span className="inline-block ml-3">{tt.trusted}</span>
        </li>
        <li className="flex flex-row items-center">
          <StarIcon height={36} width={36} className="flex-shrink-0" />
          <span className="inline-block ml-3">{tt.reviews}</span>
        </li>
        <li className="flex flex-row items-center">
          <PersonIcon height={36} width={36} className="flex-shrink-0" />
          <span className="inline-block ml-3">{tt.helped}</span>
        </li>
      </ul>
    </section>
  );
}

function useTranslations() {
  return {
    trusted: (
      <Trans id="SocialProofBar.trusted">
        Trusted by <b className="whitespace-nowrap">70k+ active members</b>
      </Trans>
    ),
    reviews: (
      <Trans id="SocialProofBar.reviews">
        <b>4.7</b> out of 5 based on <span className="whitespace-nowrap">9,300+ reviews</span>
      </Trans>
    ),
    helped: (
      <Trans id="SocialProofBar.helped">
        We've helped <b className="whitespace-nowrap">2.2m+ people</b> improve their health
      </Trans>
    ),
  };
}
