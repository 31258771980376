import * as React from "react";

function SpainFlagIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      width="100%"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 4C16.602 4 10.157 8.028 6.697 14h34.605C37.842 8.028 31.398 4 24 4zM41.302 34H6.698c3.46 5.972 9.904 10 17.302 10s13.843-4.028 17.302-10z"
        fill="#DD2C00"
      />
      <path
        d="M44 24c0-3.647-.992-7.056-2.697-10H6.697a19.886 19.886 0 00.001 20h34.605A19.886 19.886 0 0044 24z"
        fill="#FFC107"
      />
    </svg>
  );
}

export default SpainFlagIcon;
