import React, { useContext } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { OnboardingStep } from "../../onboarding/types";
import { localePrefix } from "../../../pages";
import { Locale } from "../../../types/Locale";
import { t, Trans } from "@lingui/macro";

export function IsItForMeSection() {
  const { locale } = useContext(pageContext);

  const tt = useTranslations();

  const imageData = useStaticQuery(graphql`
    query {
      valueOneEN: file(relativePath: { eq: "logged-out-home/value-1-en.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 604)
        }
      }
      valueOneSV: file(relativePath: { eq: "logged-out-home/value-1-sv.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 604)
        }
      }
      valueOneES: file(relativePath: { eq: "logged-out-home/value-1-es.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 604)
        }
      }

      valueTwo: file(relativePath: { eq: "logged-out-home/value-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 604)
        }
      }

      valueThreeEN: file(relativePath: { eq: "logged-out-home/value-3-en.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 604)
        }
      }
      valueThreeSV: file(relativePath: { eq: "logged-out-home/value-3-sv.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 604)
        }
      }
      valueThreeES: file(relativePath: { eq: "logged-out-home/value-3-es.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 604)
        }
      }
    }
  `);

  const imageSourceValueOne =
    locale === Locale.SV
      ? imageData.valueOneSV?.childImageSharp?.gatsbyImageData
      : locale === Locale.ES
      ? imageData.valueOneES?.childImageSharp?.gatsbyImageData
      : imageData.valueOneEN?.childImageSharp?.gatsbyImageData;

  const imageSourceValueTwo = imageData.valueTwo?.childImageSharp?.gatsbyImageData;

  const imageSourceValueThree =
    locale === Locale.SV
      ? imageData.valueThreeSV?.childImageSharp?.gatsbyImageData
      : locale === Locale.ES
      ? imageData.valueThreeES?.childImageSharp?.gatsbyImageData
      : imageData.valueThreeEN?.childImageSharp?.gatsbyImageData;

  return (
    <section className="bg-light-beige py-[4.5rem] px-4 content-auto">
      {/* Headings */}
      <h2 className=" m-0 uppercase text-center tracking-widest text-base font-medium">{tt.subheader}</h2>
      <h3 className="m-0 text-center mt-4 text-[1.6875rem] leading-[2.25rem] md:text-4xl font-normal">
        {tt.header}
      </h3>

      {/* Values */}
      <div className="flex flex-col items-center justify-center space-y-12 lg:space-y-10 mt-12 max-w-7xl mx-auto">
        {/* First */}
        <div className="flex flex-col items-center lg:flex-row justify-between lg:space-x-14">
          <GatsbyImage
            image={imageSourceValueOne}
            objectFit="contain"
            alt="personalization"
            className="w-full lg:w-1/2"
          />
          <div className="flex flex-col items-start justify-center max-w-lg">
            <h4 className="mt-3 text-2xl lg:text-3xl font-medium whitespace-pre-line">{tt.firstTitle}</h4>
            <p className="m-0 mt-2 font-normal text-base md:text-lg">{tt.firstDescription}</p>
          </div>
        </div>

        {/* Second */}
        <div className="flex flex-col-reverse items-center lg:flex-row justify-between lg:space-x-14">
          <div className="flex flex-col items-start justify-center max-w-lg">
            <h4 className="mt-3 text-2xl lg:text-3xl font-medium whitespace-pre-line">{tt.secondTitle}</h4>
            <p className="m-0 mt-2 font-normal text-base md:text-lg">{tt.secondDescription}</p>
          </div>

          <GatsbyImage image={imageSourceValueTwo} objectFit="contain" alt="easy" className="w-full lg:w-1/2" />
        </div>

        {/* Third */}
        <div className="flex flex-col items-center lg:flex-row justify-between lg:space-x-14">
          <GatsbyImage
            image={imageSourceValueThree}
            objectFit="contain"
            alt="community"
            className="w-full lg:w-1/2"
          />
          <div className="flex flex-col items-start justify-center max-w-lg">
            <h4 className="mt-3 text-2xl lg:text-3xl font-medium whitespace-pre-line">{tt.thirdTitle}</h4>
            <p className="m-0 mt-2 font-normal text-base md:text-lg">{tt.thirdDescription}</p>
          </div>
        </div>

        {/* CTA */}
        <Link
          to={`${localePrefix[locale]}/onboarding/${OnboardingStep.GENDER}`}
          className="!mt-[3.375rem] md:!mt-[4.5rem] block mx-auto max-w-max font-medium text-xl bg-green text-white px-8 py-3 rounded-full hover:underline focus:opacity-95 transition-opacity duration-75"
        >
          {tt.cta}
        </Link>
      </div>
    </section>
  );
}

function useTranslations() {
  return {
    subheader: t({
      id: "IsItForMeSection.subheader",
      message: "Is it for me?",
    }),
    header: (
      <Trans id="IsItForMeSection.header">
        You deserve<br></br>
        <b className="whitespace-nowrap">a better health journey</b>
      </Trans>
    ),
    firstTitle: t({
      id: "IsItForMeSection.firstTitle",
      message: "Personalization means\nmade for you",
    }),
    firstDescription: t({
      id: "IsItForMeSection.firstDescription",
      message:
        "We’ll guide you to the delicious foods that will help you meet your goals. Whether you’re trying to manage type 2 diabetes or looking for help with weight loss, we can guide you toward the meals that make success easier.",
    }),
    secondTitle: t({
      id: "IsItForMeSection.secondTitle",
      message: "Enjoy life without hunger,\ncravings & struggle",
    }),
    secondDescription: t({
      id: "IsItForMeSection.secondDescription",
      message:
        "When you find the food that’s right for you, everything gets easier. Say goodbye to hunger, and welcome a quieter feeling: satisfaction. Many members say their cravings fell away when they stopped dieting and started eating better.",
    }),
    thirdTitle: t({
      id: "IsItForMeSection.thirdTitle",
      message: "Stay confident — you’re\non the right track",
    }),
    thirdDescription: t({
      id: "IsItForMeSection.thirdDescription",
      message:
        "Our app helps you visualize your journey to better health with daily tips, tracking, recipes, and education. Follow along through our expert-led practical guides about anything and everything — from budgeting, to navigating social situations, and much more!",
    }),
    cta: t({
      id: "IsItForMeSection.cta",
      message: "Sign up today",
    }),
  };
}
