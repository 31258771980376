import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import PrevButtonIcon from "../icons/PrevButtonIcon";
import NextButtonIcon from "../icons/NextButtonIcon";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react.es";
import { t, Trans } from "@lingui/macro";

export function SuccessStoriesSection() {
  const imageData = useStaticQuery(graphql`
    query {
      jane: file(relativePath: { eq: "logged-out-home/success-story-jane.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 480)
        }
      }
      terri: file(relativePath: { eq: "logged-out-home/success-story-terri.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 480)
        }
      }
      nick: file(relativePath: { eq: "logged-out-home/success-story-nick.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 480)
        }
      }
      candace: file(relativePath: { eq: "logged-out-home/success-story-candace.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 480)
        }
      }
      calvin: file(relativePath: { eq: "logged-out-home/success-story-calvin.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 480)
        }
      }
    }
  `);

  const tt = useTranslations();

  const [sliderLoaded, setSliderLoaded] = useState(false);
  const [sliderRef, sliderInstanceRef] = useKeenSlider({
    renderMode: "performance",
    mode: "free-snap",
    loop: true,
    slides: { perView: 1.2, spacing: 15, origin: "center" },
    breakpoints: {
      "(min-width: 1280px)": {
        slides: { perView: 1.75, spacing: 15, origin: "center" },
      },
      "(min-width: 1444px)": {
        slides: { perView: 2.25, spacing: 15, origin: "center" },
      },
    },
    created() {
      setSliderLoaded(true);
    },
  });

  return (
    <section className="py-[4.5rem] content-auto">
      <div className="max-w-7xl mx-auto px-4">
        {/* Headings */}
        <h2 className="m-0 uppercase tracking-widest text-base font-medium">{tt.subheader}</h2>
        <div className="flex flex-row items-center overflow-hidden">
          <h3 className="m-0 mt-4 text-[1.6875rem] leading-[2.25rem] md:text-4xl font-normal">{tt.header}</h3>
          {/* Controls */}
          {sliderLoaded && sliderInstanceRef.current && (
            <div className="w-full hidden md:flex flex-row justify-end select-none">
              <div
                className="flex w-11 h-11 mx-4 bg-green-500 left-0 z-30 rounded-full cursor-pointer items-center justify-center"
                onClick={(e) => {
                  e.stopPropagation();
                  sliderInstanceRef.current?.prev();
                }}
              >
                <PrevButtonIcon className="hover:opacity-90" />
              </div>
              <div
                className="flex w-11 h-11 mx-4 bg-green-500 right-0 z-30 rounded-full cursor-pointer items-center justify-center"
                onClick={(e) => {
                  e.stopPropagation();
                  sliderInstanceRef.current?.next();
                }}
              >
                <NextButtonIcon className="hover:opacity-90" />
              </div>
            </div>
          )}
        </div>

        {/* Carousel */}
        <div className="mt-12 -mx-4 [@media(min-width:80rem)]:ml-[calc(-100vw/2+(80rem-1rem)/2)] [@media(min-width:80rem)]:mr-[calc(-100vw/2+(80rem-1rem)/2)] ">
          <ul
            ref={sliderRef}
            className={`keen-slider w-full min-h-min flex flex-row list-none p-0 m-0 hover:cursor-grab active:cursor-grabbing overflow-x-hidden`}
          >
            <SingleSuccessStory
              image={imageData.jane?.childImageSharp?.gatsbyImageData}
              quote={tt.firstQuote}
              infoAboutPerson={tt.firstPerson}
            />
            <SingleSuccessStory
              image={imageData.terri?.childImageSharp?.gatsbyImageData}
              quote={tt.secondQuote}
              infoAboutPerson={tt.secondPerson}
            />
            <SingleSuccessStory
              image={imageData.nick?.childImageSharp?.gatsbyImageData}
              quote={tt.thirdQuote}
              infoAboutPerson={tt.thirdPerson}
            />
            <SingleSuccessStory
              image={imageData.candace?.childImageSharp?.gatsbyImageData}
              quote={tt.fourthQuote}
              infoAboutPerson={tt.fourthPerson}
            />
            <SingleSuccessStory
              image={imageData.calvin?.childImageSharp?.gatsbyImageData}
              quote={tt.fifthQuote}
              infoAboutPerson={tt.fifthPerson}
            />
          </ul>
        </div>
      </div>
    </section>
  );
}

function useTranslations() {
  return {
    subheader: t({
      id: "SuccessStoriesSection.subheader",
      message: " 600+ success stories",
    }),
    header: (
      <Trans id="SuccessStoriesSection.header">
        <span className="whitespace-nowrap">Real people. </span>
        <b className="whitespace-nowrap">Real results.</b>
      </Trans>
    ),

    firstQuote: t({
      id: "SuccessStoriesSection.firstQuote",
      message: "I feel better than ever!",
    }),
    firstPerson: t({
      id: "SuccessStoriesSection.firstPerson",
      message: "Jane, 49, lost 250 pounds (113 kilos)",
    }),
    secondQuote: t({
      id: "SuccessStoriesSection.secondQuote",
      message: "I feel 30 years younger than I am!",
    }),
    secondPerson: t({
      id: "SuccessStoriesSection.secondPerson",
      message: "Terri, 64, lost 200 pounds (91 kilos)",
    }),
    thirdQuote: t({
      id: "SuccessStoriesSection.thirdQuote",
      message: "My blood sugar is finally good.",
    }),
    thirdPerson: t({
      id: "SuccessStoriesSection.thirdPerson",
      message: "Nick, 62, lost 45 pounds (20 kilos)",
    }),
    fourthQuote: t({
      id: "SuccessStoriesSection.fourthQuote",
      message: "It’s a way of life for me now.",
    }),
    fourthPerson: t({
      id: "SuccessStoriesSection.fourthPerson",
      message: "Candace, 41, lost 150 pounds (70 kilos)",
    }),
    fifthQuote: t({
      id: "SuccessStoriesSection.fifthQuote",
      message: "Diet Doctor saved my life.",
    }),
    fifthPerson: t({
      id: "SuccessStoriesSection.fifthPerson",
      message: "Calvin, 42, lost 140 pounds (64 kilos)",
    }),
  };
}

function SingleSuccessStory({
  image,
  quote,
  infoAboutPerson,
}: {
  image: IGatsbyImageData;
  quote: string;
  infoAboutPerson: string;
}) {
  return (
    <li className="keen-slider__slide transform-gpu min-w-[310px] sm:min-w-[531px] md:min-w-[638px] lg:min-w-[851px] xl:min-w-[716px] bg-light-beige rounded-lg relative">
      <div className="flex flex-row h-full">
        <GatsbyImage
          image={image}
          className="rounded-l-lg bg-lighter-grey w-2/3 sm:w-1/2"
          alt="success story image"
        />
        <div className="flex flex-col justify-center m-4 sm:m-8 lg:m-16">
          <q className="m-0 mt-2 text-2xl sm:text-3xl italic text-black">{quote}</q>
          <p className="m-0 mt-6 text-sm sm:text-lg font-normal">{infoAboutPerson}</p>
        </div>
      </div>
    </li>
  );
}
