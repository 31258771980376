import React, { useContext } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { localePrefix } from "../../../pages";
import { OnboardingStep } from "../../onboarding/types";
import { Link } from "gatsby";
import { t, Trans } from "@lingui/macro";

export function TakeTheQuizSection() {
  const { locale } = useContext(pageContext);

  const tt = useTranslations();

  return (
    <section className="py-36 pt-[4.5rem] sm:pt-28 gradient bg-gradient-to-b from-blue-200 to-white relative content-auto">
      <div className="flex flex-col items-center">
        <h2 className="text-center m-0">
          <span className="uppercase tracking-widest text-base font-medium">{tt.subheader}</span>
          <br></br>
          <span className="text-[1.6875rem] leading-[2.25rem] md:text-4xl font-medium mt-3 block">
            {tt.header}
          </span>
        </h2>

        <Link
          to={`${localePrefix[locale]}/onboarding/${OnboardingStep.GENDER}`}
          className="!mt-[1.6875rem] md:!mt-[2.25rem] block mx-auto max-w-max font-medium text-xl bg-green text-white px-8 py-3 rounded-full hover:underline focus:opacity-95 transition-opacity duration-75"
        >
          {tt.cta}
        </Link>
      </div>
      <div className="absolute left-0 bottom-0">
        <StaticImage
          src="../../../../static/images/logged-out-home/quiz-left.png"
          height={320}
          objectFit="contain"
          alt="left"
          className="w-52 sm:w-72 xl:w-[31rem]"
        />
      </div>
      <div className="absolute right-0 bottom-0">
        <StaticImage
          src="../../../../static/images/logged-out-home/quiz-right.png"
          height={345}
          objectFit="contain"
          alt="right"
          className="w-36 sm:w-56 xl:w-[22rem]"
        />
      </div>
    </section>
  );
}

function useTranslations() {
  return {
    subheader: t({
      id: "TakeTheQuizSection.subheader",
      message: "Take the quiz",
    }),
    header: (
      <Trans id="TakeTheQuizSection.header">
        And start your<br></br>journey with us
      </Trans>
    ),
    cta: t({
      id: "TakeTheQuizSection.cta",
      message: "Get started now",
    }),
  };
}
